import {
  type ClientLoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';

import {
  MessageTemplateSettingsEditor,
  MessageTemplateSettingsUtils,
} from '../components/Message/TemplateSettings';
import { apiService } from '../services/api-service';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const id = action.params.id;
  if (!id) throw new Response('Not Found', { status: 404 });

  if (id.startsWith('hardcoded-email')) {
    return {
      type: 'hardcoded-email',
      id,
    };
  }

  const resp = await apiService.message.getTemplateSettings(id);

  return {
    type: 'markdown',
    settings: resp.data.settings,
  };
}

function EmailTemplatePreview(props: { id: string }) {
  const EmailTemplate = MessageTemplateSettingsUtils.GetHardcodedEmailTemplate(
    props.id
  );
  if (!EmailTemplate) return null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const subject = EmailTemplate.Subject(EmailTemplate.PreviewProps as any);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const body = EmailTemplate.Content(EmailTemplate.PreviewProps as any);

  return (
    <div className='w-full h-full p-10 overflow-y-auto bg-white text-black'>
      <div className='max-w-300 flex flex-col gap-10 mx-auto'>
        <div className='text-base font-bold'>
          <p className='text-base font-bold mb-2'>Subject</p>
          <p className='text-base font-normal'>{subject}</p>
        </div>

        <div>
          <p className='text-base font-bold mb-2'>Body</p>
          <div className='border rounded-xl'>{body}</div>
        </div>
      </div>
    </div>
  );
}

export function Component() {
  const data = useLoaderData<typeof clientLoader>();

  const navigate = useNavigate();
  const back = () => {
    navigate('/admin/messages/campaigns');
  };

  if (data.type === 'hardcoded-email') {
    return <EmailTemplatePreview id={data.id || ''} />;
  }
  if (data.type === 'markdown') {
    return (
      <MessageTemplateSettingsEditor
        settings={data.settings}
        onCancel={back}
        onSubmit={back}
      />
    );
  }
  return null;
}
